import {showMessageAboveForm} from "../show-message-above-form";

$('._ajax_toggle_wish_list').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const _this = $(this);
    const productId = _this.attr('data-product-id');
    const isActive = _this.attr('data-wish-list');

    if(isActive === '0'){
        $.request('ProductList::onAddToWishList', {
            loading: $.oc.stripeLoadIndicator,
            data: {'product_id': productId},
            flash: 1,
            success: function(data) {
                $.oc.flashMsg({ text: 'Produkt został dodany do Twojej listy ulubionych', class: 'success' , interval: 3 });
                _this.attr('data-wish-list', '1')
                    .addClass('active')
                    .find('.fas.fa-heart').fadeIn()
                    .find('.far.fa-heart').fadeOut();
            },
            error: function (data) {
                console.log(data);
            },
        });
    }else{
        $.request('ProductList::onRemoveFromWishList', {
            loading: $.oc.stripeLoadIndicator,
            flash: 1,
            data: {'product_id': productId},
            success: function(data) {
                $.oc.flashMsg({ text: 'Produkt został usunięty z Twojej listy ulubionych', class: 'success' , interval: 3 });
                _this.attr('data-wish-list', '0')
                    .removeClass('active')
                    .find('.fas.fa-heart').fadeOut()
                    .find('.far.fa-heart').fadeIn();
            },
            error: function (data) {
                console.log(data);
            }
        });
    }
});
