window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('simplelightbox');
require('./rwd-checker');
require('./dropdown-menu');
require('./mobile-menu');
require('./form-animation');
require('./user/user-dropdown');
require('./user/user-ajax-forms');
require('./user/user-wish-list');

function onloadCallback() {
    if($('#google-captcha').length){
        grecaptcha.render('google-captcha', {
            'sitekey' : '6LfpZgEVAAAAAJEGcQsiazZmQWxu1kUsvPCwmkEH'
        });
    }
};
window.onloadCallback = onloadCallback;

$(function () {
    require('./cookie-bar');
    require('./cart/add-to-cart-btn');
    require('./cart/cart-list');
    require('./cart/cart-header');
    require('./cart/change-quantity-btn');
    require('./order/checkout-form');
    require('./product-slider');
    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });
    if($('.simplelightbox').length > 0){
        $('.simplelightbox').simpleLightbox();
    }
});