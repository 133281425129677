const slider = $('.product__slider');

if(slider.length > 0){
    $('.product__thumb').click(function (e) {
        e.preventDefault();
        const activeImage = $(this).attr('data-thumb');
        $('.product__thumb').removeClass('active');
        $(this).addClass('active');
        $('.product__image.active').removeClass('active').hide();
        $('.product__images')
            .find(`.product__image[data-image='${activeImage}']`)
            .addClass('active')
            .fadeIn();
    });
}