export const ERROR_MESSAGE = 'Wystąpił nieoczekiwany błąd, odśwież stronę i spróbuj ponownie';

export function showMessageAboveForm(form, message, error= false) {
    let messageBox = $('.message--ajax');
    if(form.length > 0 && messageBox.length === 0){
        const messageHTML = '<div class="message message--ajax" style="display: none;"><p></p></div>';
        $(messageHTML).insertBefore(form);
    }
    if(form.length > 0){
        if(messageBox.hasClass('message--error')){
            messageBox.removeClass('message--error');
        }
        messageBox = $('.message--ajax');
        messageBox.find('p').text(message);
        if(error){
            messageBox.addClass('message--error');
        }
        messageBox.fadeIn('fast');
        scrollToTarget('.message--ajax');
    }else{
        return false;
    }
}

export function scrollToTarget(target){
    const $target = $(target);
    if($target.length > 0 && $target.offset().top < window.pageYOffset){
        $(window).scrollTop($target.offset().top - 100);
    }
}

export function loader(form, hideLoader = false) {
    let loader = $('.ajaxLoader');
    let messageBox = $('.message--ajax');

    if(messageBox.length > 0 && !(hideLoader)){
        messageBox.hide();
    }
    if(hideLoader){
        loader.hide();
    }else{
        loader.show();
    }
}