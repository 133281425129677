$(window).on('load', function(){
    $(".input-effect input, textarea").val("");

    $(".input-effect input, textarea").focusout(function(){
        if($(this).val() != ""){
            $(this).addClass("has-content");
        }else{
            $(this).removeClass("has-content");
        }
    })
});