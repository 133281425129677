import CartLinkHeader from "../cart/cart-header";
import {showMessageAboveForm, loader, ERROR_MESSAGE} from '../show-message-above-form';

export default new class CheckoutFormValidation {
    constructor() {
        this.form = '_ajax_create_order';
        this.cartItemItem = 'cart__item';
        this.errorClass = 'bg-danger';
        this.buttonClass = '_create_order_button';
        this.wasValidatedClass = 'was-validated';
        this.data = {};

        this.validateForm();
        this.toggleFormSections();
        this.changeCheckoutType();
    }

    changeCheckoutType(){
        $('.checkoutType').on('click', function (e) {
            e.preventDefault();
            const checkoutType = $(this).attr('data-type');
            const scroll = $(this).attr('data-scroll');
            $('.checkoutType').removeClass('button--active');
            $(`.checkoutType[data-type='${checkoutType}']`).addClass('button--active');
            $('.checkoutTypeWrapper').hide();
            $(`.checkoutTypeWrapper--${checkoutType}`).show();
            if(scroll){
                $('html, body').animate({
                    scrollTop: $(`.checkoutTypeWrapper--${checkoutType}`).offset().top - 50
                }, 1000);
            }
        });
    }

    toggleFormSections(){
        const vat = $('.checkout--vat');
        const shipping = $('.checkout--shipping');

        $('.vat-checkbox input').change(function () {
            vat.toggleClass('d-none');
        });
        $('.shipping-checkbox input').change(function () {
            shipping.toggleClass('d-none');
        });
    }


    validateForm() {
        const _this = this;
        const form = $(`.${_this.form}`);


        form.on('submit', function (event){
            event.preventDefault();
            event.stopPropagation();

            const formData = {
                'order': {
                    'payment_method_id': form.find('input[name="payment_method_id"]:checked').val(),
                    'shipping_type_id': form.find('input[name="shipping_type_id"]:checked').val(),
                    'property': {
                        'fv_nip': form.find('input[name="fv_nip"]').val(),
                        'fv_company_name': form.find('input[name="fv_company_name"]').val(),
                        'fv_invoice': form.find('input[name="fv_invoice"]:checked').val(),
                        'fv_street': form.find('input[name="fv_street"]').val(),
                        'fv_house': form.find('input[name="fv_house"]').val(),
                        'fv_flat': form.find('input[name="fv_flat"]').val(),
                        'fv_postcode': form.find('input[name="fv_postcode"]').val(),
                        'fv_city': form.find('input[name="fv_city"]').val(),
                        'fv_country': form.find('input[name="fv_country"]').val(),
                        'shipping_name': form.find('input[name="shipping_name"]').val(),
                        'shipping_last_name': form.find('input[name="shipping_last_name"]').val(),
                        'shipping_phone': form.find('input[name="shipping_phone"]').val(),
                        'billing_notes': form.find('textarea[name="billing_notes"]').val(),
                    },
                    'accept_terms': form.find('input[name="accept_terms"]:checked').val(),
                },
                'user': {
                    'email': form.find('input[name="email"]').val(),
                    'name': form.find('input[name="name"]').val(),
                    'last_name': form.find('input[name="last_name"]').val(),
                    'phone': form.find('input[name="phone"]').val(),
                },
                'shipping_option': form.find('input[name="shipping_option"]:checked').val(),
                'shipping_address': {
                    'street': form.find('input[name="shipping_street"]').val(),
                    'house': form.find('input[name="shipping_house"]').val(),
                    'flat': form.find('input[name="shipping_flat"]').val(),
                    'postcode': form.find('input[name="shipping_postcode"]').val(),
                    'city': form.find('input[name="shipping_city"]').val(),
                    'country': form.find('input[name="shipping_country"]').val(),
                },
                'billing_address': {
                    'street': form.find('input[name="billing_street"]').val(),
                    'house': form.find('input[name="billing_house"]').val(),
                    'flat': form.find('input[name="billing_flat"]').val(),
                    'postcode': form.find('input[name="billing_postcode"]').val(),
                    'city': form.find('input[name="billing_city"]').val(),
                    'country': form.find('input[name="billing_country"]').val(),
                }
            };

            if(grecaptcha.getResponse() !== ''){
                $.request('onValidate', {
                    loading: $.oc.stripeLoadIndicator,
                    flash: 1,
                    'data': formData,
                    success: function (data) {
                        _this.createOrder(formData);
                    },
                    error: function (data) {
                        const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE
                        showMessageAboveForm(form.find('.checkout--standard'), msg, true);
                    }
                });
            }else{
                const msg = 'Potwierdź, że nie jesteś robotem';
                showMessageAboveForm(form, msg, true);
            }
        });
    }

    createOrder(formData) {
        const form = $(`.${this.form}`),
            button = $(`.${this.buttonClass}`),
            _this = this;

        $.request('MakeOrder::onCreate', {
            loading: $.oc.stripeLoadIndicator,
            flash: 1,
            'data': formData,
            success: function (response) {
                if (!!response && (response.status || !!response['X_OCTOBER_REDIRECT'])) {
                    this.success(response);
                    const msg = data.result;
                    showMessageAboveForm(form.find('.checkout--standard'), msg);
                } else {
                    _this.showNotAvailableMessage(response);
                }
            }
        });
    }

    showNotAvailableMessage(response){
        if (!response || !response.data || !response.data.cart_position_id) {
            return;
        }
        const form = $(`.${this.form}`);
        const productName = $(`.${this.cartItemItem}[data-position-id="${response.data.cart_position_id}"]`).attr('data-product-name');
        const maxQuantity = $(`.${this.cartItemItem}[data-position-id="${response.data.cart_position_id}"]`).attr('data-offer-quantity');

        const msg = `Produkt "${productName}" nie jest dostępny w takiej ilości. Dostępna ilość produktu: ${maxQuantity}`;
        showMessageAboveForm(form.find('.checkout--standard'), msg, true);
    }
}();
