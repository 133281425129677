$('.user-account__link').click(function (e) {
    e.preventDefault();
    const menu = $(this).siblings('.user-account__menu');
    if(menu.hasClass('active')){
        menu.removeClass('active').slideUp('fast');
    }else{
        menu.addClass('active').slideDown('fast');
    }
});
$('body').click(function (e) {
    if ($(e.target).closest('.user-account__link').length === 0 && $(e.target).closest('.user-account__menu').length === 0){
        $('.user-account__menu').removeClass('active').slideUp('fast');
    }
});