import CartLinkHeader from './cart-header';

export default new class ButtonAddToCart {
    constructor() {
        //Init selectors
        this.btnAddToCart = 'btn-add-to-cart';
        //Add events
        this.eventHandlers();
    }

    eventHandlers() {
        $(document).on('click', `.${this.btnAddToCart}`, (e) => {
            const button = $(e.currentTarget),
                form = button.parents('form');

            this.addOfferToCart(form, button);
        });
    }

    addOfferToCart(form, button) {
        const formId = form.find('input[name="offer_id"]').val();
        const formQuantity = parseInt(form.find('input[name="quantity"]').val());
        const cartQuantity = parseInt($(".cart-item__link[data-product-id='"+formId+"']").attr('data-product-quantity'));
        const cartData = [
            {
                'offer_id': formId,
                'quantity': formQuantity + (cartQuantity ? cartQuantity : 0),
            }
        ];

        $.request('Cart::onAdd', {
            loading: $.oc.stripeLoadIndicator,
            flash: 1,
            data: {'cart': cartData},
            success: function (response) {
                if (!!response && response.status) {
                    $.oc.flashMsg({ text: 'Produkt został dodany do koszyka', class: 'success', interval: 3 });
                    CartLinkHeader.updateBlock();
                } else {
                    $.oc.flashMsg({ text: response.message, class: 'success', interval: 3 });
                }

                button.popover('show');
                setTimeout(() => {
                    button.popover('hide');
                }, 1500);
            },
        });
    }
}();
