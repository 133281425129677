import {showMessageAboveForm, loader, ERROR_MESSAGE, scrollToTarget} from '../show-message-above-form';

$(function () {
    if($('.ajaxLoader').length < 1 && $('.ajaxForm').length > 0 ){
        const loaderHTML = "<img class='ajaxLoader' src='/themes/default/assets/images/loader.svg' >";
        $(loaderHTML).insertBefore('.ajaxForm');
    }


});


// change password
$('.button--changepass').click(function (e) {
    e.preventDefault();
    handleAjaxForm($('.form--changepass'),'ChangePassword::onAjax');
});

// change-data
$('.button--changedata').click(function (e) {
    e.preventDefault();
    handleAjaxForm($('.form--changedata'),'UserPage::onAjax');
});

// login
$('.button--login').click(function (e) {
    e.preventDefault();
    handleAjaxForm($('.form--login'),'Login::onAjax');
});

// registration
$('.button--register').click(function (e) {
    e.preventDefault();
    const form = $('.form--register');
    const formData = {
       'accept_terms': form.find('input[name="accept_terms"]:checked').val()
    };

    if(grecaptcha.getResponse() !== ''){
        $.request('onValidate', {
            'data': formData,
            success: function (data) {
                handleAjaxForm(form,'Registration::onAjax');
            },
            beforeSend: function(){
                loader(form, false);
            },
            complete: function(){
                loader(form, true);
            },
            error: function (data) {
                const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
                showMessageAboveForm(form, msg, true);
            }
        });
    }else{
        const msg = 'Potwierdź, że nie jesteś robotem';
        showMessageAboveForm(form, msg, true);
    }
});

// restore password
$('.button--restore').click(function (e) {
    e.preventDefault();
    handleAjaxForm($('.form--restore'),'RestorePassword::onAjax');
});

// reset password
$('.button--resetpass').click(function (e) {
    e.preventDefault();
    handleAjaxForm($('.form--resetpass'),'ResetPassword::onAjax');
});

function handleAjaxForm(form, ajaxRequest) {
    form.request(ajaxRequest, {
        success: function(data) {
            if (data.X_OCTOBER_REDIRECT) {
                window.location = data.X_OCTOBER_REDIRECT
            }else{
                showMessageAboveForm(form, data.message, !(data.status));
            }
        },
        beforeSend: function(){
            loader(form, false);
        },
        complete: function(){
            loader(form, true);
        },
        error: function (data) {
            showMessageAboveForm(form, ERROR_MESSAGE, true);
        }
    });
}