export default new class CartLinkHeader {
    constructor() {
        this.cartHeader = '.cart-header';
        this.cartSelector = '.cart-mini';
        this.ajaxWrapper = '_ajax_cart_link_header_wrapper';
        this.buttonRemoveItemSelector = '.cart-mini__delete-btn';
        this.updateBlock();
        this.eventHandlers();
    }


    eventHandlers() {
        if (!$(this.cartSelector).length) {
            return;
        }

        const _this = this;
        $(`.${this.ajaxWrapper}`).on({
            mouseenter: function () {
                $(_this.cartSelector).fadeIn('fast');
            },
            mouseleave: function () {
                $(_this.cartSelector).fadeOut('fast');
            }
        });
        $(document).on('click', this.buttonRemoveItemSelector, function (e) {
            const $btnRemoveItem = $(e.currentTarget);
            _this.sendRequestRemoveItem($btnRemoveItem);
        });
    }
    updateBlock() {
        if ($(`.${this.ajaxWrapper}`).length == 0 || $('body').attr('data-cart-is-available') != 1) {
            return;
        }

        $.request('Cart::onGetData', {
            update: {
                'cart/cart-header': `.${this.ajaxWrapper}`
            }
        });
    }
    sendRequestRemoveItem($btn) {
        const itemId = $btn.attr('data-id'),
            _this = this,
            // activeShippingTypeID = $(`.${this.orderForm}`).find('input[name="shipping_type_id"]').val(),
            data = {
                'cart': [itemId],
                // 'shipping_type_id': activeShippingTypeID
            };

        $.request('Cart::onRemove', {
            loading: $.oc.stripeLoadIndicator,
            flash: 1,
            'data': data,
            success: function (response) {
                _this.updateBlock();
                if (!!response && response.status && !!response.data) {
                    $.oc.flashMsg({ text: 'Produkt został usunięty z koszyka', class: 'success', interval: 3 });
                }
            }
        });
    }
}();
